import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";

import {CaseDTO, CaseFilterDTO} from "@shared_vue/openapi/caseapi/api";


const VuexModule = createModule({
    namespaced: "case",
    strict: false,
})

export class CaseStore extends VuexModule {

    public caseFilter: CaseFilterDTO = {};
    public currentCase: CaseDTO = {};
    public canCall: boolean = false;
    public currentOp: String | undefined = undefined;

    private readonly version = '0.1'
    
    public static getDefaultCaseFilter(): CaseFilterDTO {
        let cf = <CaseFilterDTO>{};
        cf.caseStates = [];
        cf.caseTypes = [];
        let rn = new Date();
        let monthAgo = new Date();
        monthAgo.setMonth(monthAgo.getMonth() -1);
        cf.startDate = monthAgo.toJSON();
        cf.endDate =  rn.toJSON();
        return cf;
    }
    
    get okToCall(){
        return this.canCall;
    }
    
    @mutation setOp(val: string | undefined){
        if (val!==this.currentOp){
            console.log('resetting filter')
            this.caseFilter = <CaseFilterDTO>{};
            this.caseFilter.caseStates = ["NEW", "OPEN", "REMINDER", "CANCELLED"];
        }
        this.currentOp = val;
        
    }

    @mutation setCanCall(canCall: boolean){
        this.canCall = canCall;
    }
    
    @mutation setCurrentCase(currentCase: CaseDTO){
        this.currentCase=currentCase;
    }
    
    get openCase(){
        return this.currentCase;
    }

    

    @mutation resetFilter(){
        this.caseFilter = <CaseFilterDTO>{};
        this.caseFilter.caseStates = ["NEW", "OPEN", "REMINDER"];
            
        
    }
    
    @mutation toggleVisible(state: string){
        let currentFilter = {...this.caseFilter};
        if (!currentFilter.caseStates)
            currentFilter.caseStates = new Array<string>();
        let index = currentFilter.caseStates.indexOf(state);
        if (index > -1){
            //remove
            currentFilter.caseStates.splice(index,1);
        } else {
            currentFilter.caseStates.push(state);
        }
        this.caseFilter = currentFilter;
        
    }

    @mutation toggleVisible2(caseType: string){
        let currentFilter = {...this.caseFilter};
        if (!currentFilter.caseTypes)
            currentFilter.caseTypes = new Array<string>();
        let index = currentFilter.caseTypes.indexOf(caseType);
        if (index > -1){
            //remove
            currentFilter.caseTypes.splice(index,1);
        } else {
            currentFilter.caseTypes.push(caseType);
        }
        this.caseFilter = currentFilter;

    }
    
    get currentFilter(){
        return this.caseFilter;
    }
    
    @mutation updateFilter(newFilter: CaseFilterDTO){
        this.caseFilter = newFilter;
    }

    // @action async fetchFreshCaseStack(){
    //     try {
    //         let results = await CaseApi.getInstance().getCases();
    //         if (results && results.status == 200) {
    //             this.setStack(results.data);
    //         }
    //     } catch (e) {
    //         //hmm
    //     }
    // }




}
