import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilPencil,
  cilCart,
  cilBasket,
  cilChevronRight,
  cilMedicalCross,
  cibCodesandbox,
  cilFingerprint,
  cilClipboard, 
  cibMessenger,
  cilShareAlt,
  cilBeachAccess,
  cilPhone,
  cilSave,
  cilWindowRestore,
  cilActionUndo,
  cilMove,
  cilSettings,
  cilChatBubble,
  cilCalendar

} from '@coreui/icons'

import { logo } from './logo'

export const iconsSet: { [key: string]: string[] } = Object.assign({}, {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilPencil,
  cilCart,
  cilBasket,
  logo,
  cilChevronRight,
  cilMedicalCross,
  cibCodesandbox,
  cilFingerprint,
  cilClipboard,
  cibMessenger,
  cilShareAlt,
  cilBeachAccess,
  cilPhone,
  cilSave,  
  cilWindowRestore,
  cilActionUndo,
  cilMove,
  cilSettings,
  cilChatBubble,
  cilCalendar
})
