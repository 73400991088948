import { render, staticRenderFns } from "./ProfileCard.vue?vue&type=template&id=74a3caac"
import script from "./ProfileCard.vue?vue&type=script&lang=js"
export * from "./ProfileCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../imicsspa/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports