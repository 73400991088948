import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue/src';
import {iconsSet as icons} from './assets/icons/icons'
import {store} from "@/store";
import {worker} from "@/mocks/browser";
import * as firebase from "firebase";
import 'firebase/auth';
import 'firebase/firestore'
import {vxm} from "@/store";
import axios from 'axios';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import visibility from 'vue-visibility-change';
import {UserManagerApiFactory} from "@shared_vue/openapi/userapi"
import VueCookies from 'vue-cookies';
import install from '@shared_vue/components/placesautocomplete/index';
import Cookies from 'js-cookie';
import { loadScript } from './mapsloader';
// import { initGoogleMaps } from './usevuemap.js';
// import * as VueGoogleMaps from 'vue2-google-maps/dist/main.js';

Vue.use(VueCookies)
// registry directive
Vue.use(visibility);
Vue.use(VueFilterDateFormat);
Vue.use(CoreuiVue);
Vue.use(install);
// initGoogleMaps();


// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAruewTbxyLgrwiDkWc0NJc55QR5GsWvk4',
//     // key: ''
//     // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
//     // v: 'OPTIONAL VERSION NUMBER',
//     libraries: 'places', //// If you need to use place input
//   }
// });
// visibility.change((evt, hidden) => {
//   console.log('hidden:'+hidden)
//   if (hidden){
//     let userApi = UserManagerApiFactory(undefined,'http://localhost:5002');
//     try {
//       userApi.userManagerInvalidateMFA().then(resp=>{
//         console.log(resp.status)
//       }).catch(err=>{
//         console.log(err)
//         if (err.response.status==403) {
//           if (err.response.headers.location!==undefined)
//           {
//             //redirect
//             if (err.response.headers.location==="/nowhereville") {
//               store.commit('auth/requireBiometric', true)
//             }
//           }
//         }
//       });
//
//     } catch (e){
//       // just do nothing for now. I'll give this a thing
//     }
//   }
// });
Vue.config.productionTip = false



// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const configOptions = {
  apiKey: "AIzaSyCu1pdi3fAksEqyjXCtBpgRfrAEk-mOnYg",
  authDomain: "trackbox-world.firebaseapp.com",
  databaseURL: "https://trackbox-world.firebaseio.com",
  projectId: "trackbox-world",
  storageBucket: "trackbox-world.appspot.com",
  messagingSenderId: "781096390213",
  appId: "1:781096390213:web:1daf5f647a6ee3d782dd2c",
  measurementId: "G-YJGMTFR2M5"
};
// try {
//   let sessionValue = Cookies.get('__session');
//   if (sessionValue) {
//     console.log('found token')
//     vxm.auth.cookieMe(sessionValue);  
//   }
//  
// } catch (err) {
//   //squash
// }
firebase.initializeApp(configOptions);
// const db = firebase.firestore();
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    console.log(user); // It shows the Firebase user
    console.log('setup firestore')
    
    // db.collection("users").doc(user.uid)
    //     .onSnapshot((doc) => {
    //       console.log("user firestore update: ", doc.data());
    //       // @ts-ignore
    //       store.commit('auth/requireBiometric', doc.data().requiresbio)
    //     });
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      // vxm.auth.cookieMe('');
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });

    const refreshToken = () => {
      console.log('refresh token 2')
      user.getIdToken(true).then(function(idToken) { // Force token refresh
        console.log(idToken); // It shows the refreshed Firebase token
        store.commit('auth/setToken', idToken);
        axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
      }).catch(function(error) {
        // Handle error
        console.error("Error refreshing token", error);
      });
    };
    setInterval(refreshToken, 45 * 60 * 1000); // 45 minutes in milliseconds

    // Also call refreshToken function immediately to set initial token
    refreshToken();
  }
});

firebase.auth().onIdTokenChanged(user=> {
  if (user){
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      console.log(idToken); // It shows the Firebase token now
      console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });
  }
})
const apiKey = 'AIzaSyBLNNIOJ-dM1z23gaqTQYJcYiPUckcvRPw';
const libraries = ['places', 'geocode']; // As per your requirement
loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}`)
    .then(() => {
      new Vue({
        router,
        store,
        //CIcon component documentation: https://coreui.io/vue/docs/components/icon
        icons,
        render: h => h(App),
      }).$mount('#app')
    });

axios.defaults.withCredentials = true