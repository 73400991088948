let loadedScripts: Record<string, boolean> = {};

export function loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
        if (loadedScripts[src]) {
            resolve();
            return;
        }

        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            loadedScripts[src] = true;
            resolve();
        };
        script.onerror = reject;
        document.body.appendChild(script);
    });
}