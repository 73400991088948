import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";



import {LoginState} from "@/store/auth/types";
import {UserApi} from "@shared_vue/services/userservice";
import {AxiosResponse} from "axios";

import firebase from "firebase";
import {LoginDTO} from "@shared_vue/types/LoginDTO";
import {
    Configuration as UserConfiguration,
    GlobalAuthData,
    UserManagerApiFactory
} from "../../../../shared_vue/openapi/userapi";
import {store} from "@/store";

const VuexModule = createModule({
  namespaced: "auth",
  strict: false,
})

export class AuthStore extends VuexModule {


    public loginState: LoginState = LoginState.LoggedOut;
    public usr: firebase.User|null=null;
    public idToken: string ='';
    public bioRequired: boolean = false;
    public checkingUser: boolean = false;
    private userApi2 = UserManagerApiFactory(<UserConfiguration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);
    private cookied: boolean = false;
    private justLoggedIn: boolean = false;

    @mutation setJustLoggedIn(val: boolean)
    {
        this.justLoggedIn=val;
    }
    
    get hasJustLoggedIn(){
        return this.justLoggedIn;
    }
    
    @mutation setCheckingUser(val: boolean){
        this.checkingUser = val;
    }
    
    @mutation setUser(val:firebase.User){
        this.usr=val;
    }

    @mutation requireBiometric(val:boolean){
        this.bioRequired=val;
    }

    @mutation setToken(val:string){
        this.idToken=val;
    }

    get User() {
        return this.usr;
    }

    get waitWhileChecking(){
        return this.checkingUser;
    }


    get LoggedIn(){
        return (this.usr!=null);
    }

    @action async loginNoRedirect(loginDto: LoginDTO) {
        let result = UserApi.getInstance().loginNoRedirect(loginDto.username, loginDto.password);
        return await result;
    }



    public signInWithCustomToken(token:string){
        firebase.auth().signInWithCustomToken(token).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(error);
        });
    }

    @action
    async signout(){
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
        }).catch(function(error) {
            // An error happened.
        });
    }

    get Cookied() {
        return this.cookied;
    }
    
    @action async cookieMe(cookieval: string|undefined) {
        //check for session cookie
        if (this.Cookied){
            console.log('already cookied')
            return;
        }
        try {
            this.setCheckingUser(true);
            if (cookieval) {
                let customToken = await this.userApi2.userManagerGlobalVerifyCookie();
                if (customToken.status == 200) {
                    console.log(customToken.data)
                    console.log('got custom token, signing in...');
                    await firebase.auth().signInWithCustomToken(customToken.data);
                    this.cookied = true;
                    //will we be stuck in an infinite loop?
                }
            } else {
                console.log('requesting cookie!')
                let result = await this.userApi2.userManagerGlobalAuth(<GlobalAuthData>{token: this.idToken})
                console.log(result);
                if (result.status == 200) { //change cookie for token
                    console.log('got cookie, exchanging for token...')
                    let customToken = await this.userApi2.userManagerGlobalVerifyCookie();
                    if (customToken.status == 200) {
                        console.log(customToken.data)
                        console.log('got custom token, signing in...');
                        // this.signInWithCustomToken(customToken.data);
                        firebase.auth().signInWithCustomToken(customToken.data).catch(function (error) {
                            // Handle Errors here.
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log(error);
                        });
                        this.cookied = true;
                        //will we be stuck in an infinite loop?
                    }
                }
            }
        } finally {
            this.setCheckingUser(false);
        }
    }

    get currentLoginState(): LoginState {
        return this.loginState;
    }

    get UserId(): string | undefined {
        return this.usr?.uid;
    }

    get Email(): string | null | undefined {
        return this.usr?.email;
    }

    get DisplayName(): string | null | undefined {
        return this.usr?.displayName;
    }

    get Token(): string | null | undefined {
        return this.idToken;
    }

}
