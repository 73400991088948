import Vue from 'vue'
import Router from 'vue-router'
import ProfilePage from "@/views/pages/ProfilePage.vue";
import * as firebase from "firebase";
import 'firebase/auth';


const DashContainer = () => import('../containers/DashContainer.vue');
const TheContainer = () => import('../containers/TheContainer.vue');
const Home = () => import('../views/Home.vue');
const Dashboard = () => import('../views/Dashboard.vue');
const CaseStack = () => import('@/views/pages/CaseStack.vue');
const LiveCase = () => import('@/views/pages/LiveCase.vue')
const ExistingCase = () => import('@/views/pages/ExistingCase.vue')
const CaseManagement = () => import('@/views/pages/CaseManagement.vue')
const EmergencyCase = () => import('@/views/pages/EmergencyCase.vue')
const CallPage = () => import('@/views/pages/CallPage.vue')
const TrackMePage = () => import('@/views/pages/TrackMePage.vue')
const ErpcPage = () => import('@/views/pages/ErpcList.vue')
const ErpcDetail = () => import('@/views/pages/ErpcDetail.vue')
const ErpcConnectMe = () => import('@/views/pages/ErpcConnectMe.vue')
const ErpcMemberList = () => import('@/views/pages/ErpcMemberList.vue')
Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        }
      ]
    },
    {
      path: '/dashboard',
      component: DashContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'erpcs',
          name: 'ERPCs',
          component: ErpcPage
        },
        {
          path: 'company/:companyid',
          name: 'Company',
          component: ErpcDetail,
          props: ({ params }) => ({ companyid: params.companyid })
        },
        {
          path: 'erpc/:id',
          name: 'Erpc',
          component: ErpcConnectMe,
          props: ({ params }) => ({ id: params.id })
        },
        {
          path: 'erpcmembers/:branchid',
          name: 'ErpcMemberList',
          component: ErpcMemberList,
          props: ({ params }) => ({ branchid: params.branchid })
        },
        {
          path: 'cases',
          name: 'Case Stack',
          component: CaseStack
        },
        {
          path: 'call',
          name: 'Call Test',
          component: CallPage
        },
        {
          path: 'trackme/:caseid',
          name: 'Track Me',
          component: TrackMePage,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'livecase/:caseid',
          name: 'Live Case',
          component: LiveCase,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'casemanagement',
          name: 'Case Management',
          component: CaseManagement
        },
        {
          path: 'casemanagement/:caseid',
          name: 'Case Management',
          component: CaseManagement,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'casecapture',
          name: 'Case Capture',
          component: ExistingCase
        },
        {
          path: 'user/:guid',
          name: 'User',
          component: ProfilePage,
          props: ({ params }) => ({ guid: params.guid })
        },
        {
          path: 'casecapture/:caseid',
          name: 'Existing Case',
          component: ExistingCase,
          props: ({ params }) => ({ caseid: params.caseid })
        },
        {
          path: 'emergencycase',
          name: 'Emergency Dispatch',
          component: EmergencyCase
        },

      ]
    }
  ]
})
router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      // If logged in, proceed to the route
      next();
    } else {
      // If not logged in and trying to access a protected route, redirect to the home page
      if (to.path !== '/') {
        console.log('nah fam');
        next('/');
      } else {
        // If trying to access the home page, allow
        next();
      }
    }
  });
});

export default router
